import React, { useCallback, useEffect, useState } from "react";
import { JobInt } from "../../typescript/interfaces/JobInterface";
import JobDropDown from "./tools/JobDropDown";
import {
  ApplicantInt,
  OptionsInt,
} from "../../typescript/interfaces/AppInterface";
import { getApplicantsById } from "../../utils/applicantFunctions";
import ApplicantSidebar from "../applicants-sidebar/ApplicantSidebar";
import LoadingWidget from "../widgets/LoadingWidget";

interface ApplicantsProps {
  jobs: JobInt[];
}

// update date to today on payment confirmation for 45 day calculation

function Applicants(props: ApplicantsProps) {
  const getInitialJob = useCallback(() => {
    const storedJobId = localStorage.getItem("selectedJobId");
    if (storedJobId && props.jobs.length > 0) {
      const storedJob = props.jobs.find((job) => job._id === storedJobId);
      if (storedJob) {
        return storedJob;
      }
    }
    return props.jobs[0];
  }, [props.jobs]);
  const [selectedJob, setSelectedJob] = useState(getInitialJob);
  const [jobOptions, setJoboptions] = useState<OptionsInt[]>([]);
  const [applicants, setApplicants] = useState<ApplicantInt[]>([]);
  const [loading, setLoading] = useState(true);
  const handleOptionClick = (option: OptionsInt) => {
    setSelectedJob(option.value);
    // add to cache
  };

  //if selecting then

  useEffect(() => {
    const getJobOptions = () => {
      if (props.jobs) {
        const options = props.jobs.map((job) => {
          return { value: job, label: job.title };
        });
        setJoboptions(options);
      }
    };
    const getApplicants = async () => {
      setLoading(true);
      if (selectedJob._id) {
        const appList = await getApplicantsById(selectedJob._id);

        setApplicants(appList);
      }
      setLoading(false);
    };

    getJobOptions();
    getApplicants();
  }, [props.jobs, selectedJob]);

  useEffect(() => {
    // Store the current page number in localStorage
    if (selectedJob._id) localStorage.setItem("selectedJobId", selectedJob._id);
  }, [selectedJob]);

  return (
    <div
      className="w100"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "0px 10px",
      }}
    >
      <div className="w100" style={{ maxWidth: "1000px", height: "100%" }}>
        {!loading && (
          <JobDropDown
            options={jobOptions}
            value={{ value: selectedJob, label: selectedJob.title }}
            onSelect={handleOptionClick}
          />
        )}

        {/* display jobs */}
        {!loading ? (
          applicants.length > 0 ? (
            <ApplicantSidebar aplicants={applicants} />
          ) : (
            <div>Aún no tienes candidatos</div>
          )
        ) : (
          // center
          <div className="flx flx-center mt-25">
            <LoadingWidget loading={true} />
          </div>
        )}
      </div>
      {/* add the list of applicants  */}
    </div>
  );
}

export default Applicants;
