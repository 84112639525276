import { useCallback, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import SubBar from "../components/dashboard/SubBar";
import Positions from "../components/dashboard/Positions";
import Applicants from "../components/dashboard/Applicants";
import { getPostedJobsById } from "../utils/applicantFunctions";
import { getAuth } from "firebase/auth";
import { JobInt } from "../typescript/interfaces/JobInterface";
import LoadingWidget from "../components/widgets/LoadingWidget";
import { Link } from "react-router-dom";
import { getBusinessUserPosts } from "../utils/businessUserFunctions";

function Dashboard() {
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const [selectedPage, setSelectedPage] = useState(() => {
    return localStorage.getItem("currentPage") || "vacantes";
  });
  const [isLoading, setIsLoading] = useState(true);
  const [postsAvailable, setPostsAvailable] = useState<number>(0);

  const [currPositions, setCurrPositions] = useState<JobInt[] | undefined>();
  const auth = getAuth();
  const getInitialPositions = useCallback(async () => {
    if (auth.currentUser) {
      try {
        const user = auth.currentUser.email;
        const positions = await getPostedJobsById(user as string);
        setCurrPositions(positions);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    }
  }, [auth]);

  const getPostsAvailable = useCallback(async () => {
    if (auth.currentUser) {
      try {
        setIsLoadingPosts(true);
        const user = auth.currentUser.email;
        const posts = await getBusinessUserPosts(user as string);
        console.log(posts);
        setPostsAvailable(posts.postsAvailable + posts.freePost);
      } catch (error) {
        console.error("Error fetching available posts:", error);
      } finally {
        setIsLoadingPosts(false);
      }
    }
  }, [auth]);

  useEffect(() => {
    getInitialPositions();
    getPostsAvailable();
  }, [getInitialPositions, getPostsAvailable]);

  useEffect(() => {
    // Store the current page number in localStorage
    localStorage.setItem("currentPage", selectedPage);
    console.log(selectedPage);
  }, [selectedPage]);

  return (
    <div className="w100 flx flx-col " style={{ minHeight: "100vh" }}>
      <Navbar
        scrollPast={true}
        lockNavbar={true}
        highlightDash={true}
        hideDash={true}
      />
      <SubBar setSelectedPage={setSelectedPage} loading={isLoading} />
      {/* content */}

      <div>
        {!isLoading && currPositions ? (
          selectedPage === "vacantes" ? (
            // react fragment and put the different ones for mobile and desktop
            <Positions
              positions={currPositions}
              getPositions={getInitialPositions}
              postsAvailable={postsAvailable}
            />
          ) : currPositions.length > 0 ? (
            <Applicants jobs={currPositions} />
          ) : (
            <div className="flx-col flx-center txt-s4 mt-50 ">
              <div className="mb-50" style={{ textAlign: "center" }}>
                ¡Añade tu primera vacante para empezar a recibir aplicantes!
              </div>
              <div>
                {" "}
                <Link to="/post-job" className="button-hover">
                  <div
                    className="add-position-btn flx flx-center"
                    style={{ whiteSpace: "nowrap", borderRadius: "50px" }}
                  >
                    <div className="">Crear Vacante</div>
                  </div>
                </Link>
              </div>
            </div>
          )
        ) : (
          <div className="flx flx-center mt-25">
            <LoadingWidget loading={isLoading || isLoadingPosts} />
          </div>
        )}
      </div>

      {/* 
      <SignOut /> */}
    </div>
  );
}

export default Dashboard;
