import React from "react";
import flex from "../../assets/images/landing/flex.png";
import packet from "../../assets/images/landing/packet.png";
import clipboard from "../../assets/images/landing/clipboard.png";
interface PriceCardProps {
  name: string;
  description: string;
  pointers: string[];
  img: string;
  price: number;
  suffix?: string;
}
function PriceCard(props: PriceCardProps) {
  return (
    <div className="bl-price-card">
      <img className="bl-price-card-img" src={props.img} alt="" />
      <div>
        <div className="bl-price-card-title">{props.name}</div>
      </div>
      <div className="mt-25 bl-price-card-description">{props.description}</div>
      <div className="mt-25">
        <ul className="bl-price-card-pointers">
          {props.pointers.map((pointer, index) => {
            return <li key={index}>{pointer}</li>;
          })}
        </ul>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "10px",
          fontSize: "12px",
        }}
      >
        {props.suffix}
      </div>
      <div className="bl-price-card-price">${props.price}</div>
    </div>
  );
}

function PriceCards() {
  return (
    <div className="bl-price-cards">
      <PriceCard
        name={"Estandar"}
        description={"Perfecto para empresas pequeñas y medianas"}
        pointers={[
          "Publicación activa por 45 días",
          "Gestión en línea de vacantes y postulantes",
          "Difusión multicanal",
          "Evaluación asistida por inteligencia artificial",
        ]}
        img={clipboard}
        price={49}
      />
      <PriceCard
        name={"Paquete"}
        description={
          " Para empresas con necesidades de contratación más frecuentes."
        }
        pointers={[
          "Todos los beneficios de la publicación Estándar",
          "Paquetes de 3, 5 o 10 publicaciones",
          "Descuentos progresivos por cantidad",
        ]}
        img={packet}
        price={135}
        suffix="*desde"
      />
      <PriceCard
        name={"Flex"}
        description={
          "La solución más flexible para empresas con necesidades de contratación continuas"
        }
        pointers={[
          "Todos los beneficios de la publicación Estándar",
          "Tus publicaciones tienen prioridad",
          "7 publicaciones acumulables por mes",
        ]}
        img={flex}
        price={159}
        suffix={"* subscripción de 6 meses min."}
      />
    </div>
  );
}

export default PriceCards;
