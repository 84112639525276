import React, { useState } from "react";

import { removeNewsletterUser } from "../../utils/Newsletter/newsletterFunctions";
import LoadingWidget from "../../components/widgets/LoadingWidget";

function Unsubscribe() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUnsubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await removeNewsletterUser(email);
      if (response !== null && response.status === 200) {
        setMessage("Te has dado de baja exitosamente.");
        setError("");
      } else {
        setMessage("");
        setError(
          "Hubo un problema al intentar darte de baja. Por favor, inténtalo de nuevo."
        );
      }
    } catch (error) {
      setMessage("");
      setError(
        "Hubo un problema al intentar darte de baja. Por favor, inténtalo de nuevo."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "400px",
        margin: "0 auto",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h2>Darse de Baja del Boletín</h2>
      <form onSubmit={handleUnsubscribe}>
        <div>
          <label htmlFor="email">Ingresa tu correo electrónico:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "10px",
              margin: "10px 0",
              boxSizing: "border-box",
            }}
          />
        </div>
        {loading ? (
          <LoadingWidget loading={loading} />
        ) : (
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#15BC7F",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Darse de Baja
          </button>
        )}
      </form>
      {message && (
        <div style={{ marginTop: "20px", color: "green" }}>{message}</div>
      )}
      {error && <div style={{ marginTop: "20px", color: "red" }}>{error}</div>}
    </div>
  );
}

export default Unsubscribe;
