import React, { useState } from "react";
import { Question } from "../../typescript/interfaces/CustomQuestionInt";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
interface CustomQuestionsProps {
  setQuestions: React.Dispatch<React.SetStateAction<Question[]>>;
  questions: Question[];
}
function CustomQuestions({ setQuestions, questions }: CustomQuestionsProps) {
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);

  const addQuestion = () => {
    if (questions.length < 3) {
      const newQuestion: Question = {
        type: "text",
        question: "",
      };
      setQuestions([...questions, newQuestion]);
    }
  };

  const removeQuestion = (index: number) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const updateQuestion = (
    index: number,
    updatedQuestion: Partial<Question>
  ) => {
    setQuestions(
      questions.map((q, i) => (i === index ? { ...q, ...updatedQuestion } : q))
    );
  };

  const addOption = (questionIndex: number) => {
    const question = questions[questionIndex];
    if (
      question &&
      question.type === "multiple" &&
      question.options &&
      question.options.length < 4
    ) {
      const updatedOptions = [...question.options, ""];
      updateQuestion(questionIndex, { options: updatedOptions });
    }
  };

  const updateOption = (
    questionIndex: number,
    optionIndex: number,
    value: string
  ) => {
    const question = questions[questionIndex];
    if (question && question.type === "multiple" && question.options) {
      const updatedOptions = [...question.options];
      updatedOptions[optionIndex] = value;
      updateQuestion(questionIndex, { options: updatedOptions });
    }
  };

  return (
    <div className="w100" style={{ paddingBottom: "15px" }}>
      <div className="postjob-container-title" style={{ marginBottom: "5px" }}>
        Preguntas Personalizadas
      </div>
      <div
        className="postjob-container-subtitle"
        style={{ marginBottom: "25px", color: "rgba(0, 0, 0, 0.6)" }}
      >
        Las preguntas seran obligatorias para cada candidato
      </div>

      {/* <div
        className="w100 txt-s2"
        style={{ color: "rgba(0, 0, 0, 0.8)", marginBottom: "10px" }}
      >
        Las preguntas seran obligatorias para cada candidato
      </div> */}
      <div className="w100 flx-col " style={{ gap: "25px" }}>
        {questions.map((question, index) => (
          <div
            key={index}
            style={{ fontSize: "18px" }}
            className="postjob-custom-questions-container-row-container"
          >
            Pregunta {index + 1}
            <div className="postjob-custom-questions-container-row">
              <input
                type="text"
                className="postjob-custom-questions-container-row-question"
                value={question.question}
                onChange={(e) =>
                  updateQuestion(index, { question: e.target.value })
                }
                placeholder="Escribe tu pregunta"
                style={{ fontSize: "16px" }} // Prevents auto-zoom
              />

              <div className="postjob-custom-questions-container-row-select-wrapper">
                <div
                  className="postjob-custom-questions-container-row-select"
                  style={{ fontSize: "16px" }}
                  onClick={() =>
                    setOpenDropdown(openDropdown === index ? null : index)
                  }
                >
                  {question.type === "text" ? "Texto" : "Opción múltiple"}
                </div>
                {openDropdown === index && (
                  <div className="postjob-custom-questions-container-row-select-options">
                    <div
                      className="postjob-custom-questions-container-row-select-option"
                      onClick={() => {
                        updateQuestion(index, {
                          type: "text",
                          options: undefined,
                        });
                        setOpenDropdown(null);
                      }}
                    >
                      Texto
                    </div>
                    <div
                      className="postjob-custom-questions-container-row-select-option"
                      onClick={() => {
                        updateQuestion(index, {
                          type: "multiple",
                          options: [""],
                        });
                        setOpenDropdown(null);
                      }}
                    >
                      Opción múltiple
                    </div>
                  </div>
                )}
              </div>

              <button
                type="button"
                className="button-hover desktop-only"
                style={{
                  color: "red",

                  backgroundColor: "inherit",
                }}
                onClick={() => removeQuestion(index)}
              >
                <DeleteOutlineIcon />
              </button>
            </div>
            {question.type === "multiple" && question.options && (
              <div className="postjob-custom-questions-container-row-options">
                {question.options.map((option, optionIndex) => (
                  <input
                    key={optionIndex}
                    className="postjob-custom-questions-container-row-options-input"
                    type="text"
                    value={option}
                    style={{ fontSize: "16px" }}
                    onChange={(e) =>
                      updateOption(index, optionIndex, e.target.value)
                    }
                    placeholder={`Opción ${optionIndex + 1}`}
                  />
                ))}
                {question.options.length < 4 && (
                  <button
                    type="button"
                    className="custom-questions-options-button laburo-green flx-center flx"
                    onClick={() => addOption(index)}
                    style={{
                      fontSize: "14px",
                      backgroundColor: "inherit",
                      gap: "5px",
                    }}
                  >
                    Añadir opción
                    <AddIcon style={{ fontSize: "16px" }} />
                  </button>
                )}
              </div>
            )}
            <button
              type="button"
              className=" mobile-only"
              style={{
                color: "red",
                fontSize: "14px",
                backgroundColor: "inherit",
              }}
              onClick={() => removeQuestion(index)}
            >
              eliminar
            </button>
          </div>
        ))}

        {questions.length < 3 && (
          <button
            type="button"
            className="laburo-green button-hover añadir-pregunta-button"
            style={{
              fontSize: "16px",
              backgroundColor: "inherit",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
                alignItems: "center",
              }}
              onClick={addQuestion}
            >
              Añadir Pregunta
              <AddIcon style={{ fontSize: "20px" }} />
            </div>
          </button>
        )}
      </div>
    </div>
  );
}

export default CustomQuestions;
