import React from "react";
import "../../assets/styles/landing/businessLanding.css";

interface StepCardProps {
  number: number;
  title: string;
  subtitle: string;
  setScreenNumber: React.Dispatch<React.SetStateAction<number>>;
}
interface StepsCardsProps {
  setScreenNumber: React.Dispatch<React.SetStateAction<number>>;
}

function StepCard(props: StepCardProps) {
  return (
    <div
      className="bl-steps-card button-hover"
      onMouseEnter={() => {
        props.setScreenNumber(props.number);
      }}
    >
      <div className="step-card-number">{props.number}</div>
      <div className="step-card-title">{props.title}</div>
      <div className="step-card-subtitle">{props.subtitle}</div>
    </div>
  );
}

function StepsCards(props: StepsCardsProps) {
  return (
    <div className="bl-stepscards-container">
      <div
        className="bl-title-text-size mb-50 flx flx-center"
        style={{ maxWidth: "650px" }}
      >
        ¿Cómo funciona?
      </div>
      <StepCard
        number={1}
        title={"Publica tu vacante fácilmente"}
        subtitle={"Crea y publica tu oferta en minutos"}
        setScreenNumber={props.setScreenNumber}
      />
      <StepCard
        number={2}
        title={"Conecta con miles de profesionales"}
        subtitle={"Difundimos tu vacante para atraer a los mejores candidatos"}
        setScreenNumber={props.setScreenNumber}
      />
      <StepCard
        number={3}
        title={"Encuentra al talento ideal"}
        subtitle={
          "Gestiona postulantes eficientemente con nuestro panel asistido por IA"
        }
        setScreenNumber={props.setScreenNumber}
      />
    </div>
  );
}

export default StepsCards;
