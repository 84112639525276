import React from "react";
import video2 from "../../assets/videos/stepCards/selectapplicant.mp4";
import video1 from "../../assets/videos/stepCards/createposition.mp4";
import video3 from "../../assets/videos/stepCards/browsejobs.mp4";
interface ScreenCarouselProps {
  screen: number;
}
function ScreenCarousel(props: ScreenCarouselProps) {
  let videoSrc = "";
  if (props.screen === 1) videoSrc = video1;
  if (props.screen === 2) videoSrc = video3;
  if (props.screen === 3) videoSrc = video2;

  return (
    <div className="bl-screen-carousel">
      <video
        key={props.screen} // Add key to force re-render on screen change
        height={"650px"}
        width={"300px"}
        autoPlay
        loop
        muted
        playsInline
        style={{
          objectFit: "cover",
          borderRadius: "25px",
        }}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default ScreenCarousel;
