import React from "react";
import "../assets/styles/navbar.css";
import "../assets/styles/global.css";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import { getAuth, signOut } from "firebase/auth";
import useAuth from "../utils/useAuth";

interface navProps {
  scrollPast: boolean;
  hidePublish?: boolean;
  hideSearch?: boolean;
  hideHireButton?: boolean;
  hideDash?: boolean;
  hideSignIn?: boolean;
  lockNavbar?: boolean;
  highlightJobs?: boolean;
  highlightLogin?: boolean;
  highlightPost?: boolean;
  highlightDash?: boolean;
}
function Navbar(props: navProps) {
  const auth = getAuth();
  const { user } = useAuth();
  const [navOpen, setNavOpen] = useState(false);

  const toggleMenu = () => {
    setNavOpen(!navOpen);
  };

  const handleOutsideClick = () => {
    setNavOpen(false);
  };

  // useEffect(() => {
  //   const auth = getAuth();
  //   const user = auth.currentUser;

  //   if (user != null) {
  //     setShowDashboard(true);
  //   }
  // }, []);
  return (
    <div
      className="nav-container"
      style={{
        backgroundColor: `${props.scrollPast ? "white" : ""}`,
        position: props.lockNavbar ? "static" : "fixed",
      }}
    >
      <div className="laburo-logo-txt">
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: `${props.scrollPast ? "black" : "white"}`,
          }}
        >
          Laburo
        </Link>
      </div>
      <div className=" nav-inner-container ">
        {/* mobile */}
        <div
          className="flx "
          style={{
            gap: "20px",
            width: "100%",

            justifyContent: "right",
          }}
        >
          <div className="flx  mobile-only " style={{ alignItems: "flex-end" }}>
            {/* if signed in show dash else person */}
            {user !== null ? (
              <Link to="/dashboard">
                <SpaceDashboardIcon
                  style={{
                    fontSize: "30px",
                    color: `${props.scrollPast ? "black" : "white"}`,
                    opacity: props.hideDash ? "0.4" : "1",
                  }}
                />
              </Link>
            ) : (
              <Link to={"/ingresar"}>
                <PersonIcon
                  style={{
                    fontSize: "30px",
                    color: `${props.scrollPast ? "black" : "white"}`,
                    opacity: props.hideSignIn ? "0.4" : "1",
                  }}
                />
              </Link>
            )}
          </div>
          {!props.hideHireButton && (
            <Link
              to="/empresas"
              className="mobile-only w100"
              style={{
                display: "flex",
                height: "100%",
                padding: "3px 0px",
                textDecoration: "none",
              }}
            >
              <div className="post-job-mobile-btn mobile-only">
                <div>Contratar</div>
              </div>
            </Link>
          )}
          <div
            onClick={toggleMenu}
            className="mobile-only  "
            style={{
              display: "flex",
              alignItems: "center",

              height: "100% !important",
            }}
          >
            <MenuIcon
              style={{
                fontSize: "35px",
                display: "flex",

                color: `${props.scrollPast ? "black" : "white"}`,
              }}
            />
          </div>
        </div>
        {/* desktop */}
        <div
          className="flx "
          style={{ alignItems: "center", width: "100%", gap: "20px" }}
        >
          {!user && (
            <div
              className="txt-s4 desktop-only nav-text-desk-search "
              style={{
                color: `${props.scrollPast ? "black" : "white"}`,
                opacity: props.highlightJobs ? "0.2" : "1",
              }}
            >
              <Link to={"/jobs"} style={{ whiteSpace: "nowrap" }}>
                Buscar Trabajos
              </Link>
            </div>
          )}

          <div
            className="person-icon txt-s4"
            style={{
              height: "100%",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            {user != null ? (
              <Link
                className="desktop-only"
                to="/dashboard"
                style={{
                  color: `${props.scrollPast ? "black" : "white"}`,
                  opacity: props.highlightDash ? "0.2" : "1",
                }}
              >
                Panel de Control
              </Link>
            ) : (
              <div
                className="txt-s4 desktop-only nav-text-desk-search "
                style={{
                  color: `${props.scrollPast ? "black" : "white"}`,
                  opacity: props.highlightLogin ? "0.2" : "1",
                }}
              >
                {!props.hideSignIn && (
                  <Link to={"/ingresar"} style={{ whiteSpace: "nowrap" }}>
                    Iniciar Sesión
                  </Link>
                )}
              </div>
            )}
          </div>
          {user === null ? (
            !props.hideHireButton && (
              <Link
                to={"/empresas"}
                className="txt-s4 desktop-only nav-text nav-txt-desk-job link-style"
                style={{
                  whiteSpace: "nowrap",
                  opacity: props.highlightPost ? "0.2" : "1",
                }}
              >
                Contratar con Laburo
              </Link>
            )
          ) : (
            <button
              className="txt-s4 desktop-only cursor-pointer"
              onClick={() => signOut(auth)}
              style={{
                color: `${props.scrollPast ? "black" : "white"}`,
                backgroundColor: "inherit",
              }}
            >
              Cerrar Sesión
            </button>
          )}
        </div>
      </div>
      {/* nav menu */}

      {navOpen && (
        <div className="nav-menu-block" onClick={handleOutsideClick}></div>
      )}

      <div className={`nav-menu ${navOpen ? "open" : ""}`}>
        <div className="nav-menu-items">
          <div>
            <Link to="/" className="link-style">
              Pagina Principal
            </Link>
          </div>
          {!user && (
            <div>
              <Link to="/jobs" className="link-style">
                Buscar Trabajos
              </Link>
            </div>
          )}

          <div>
            {user === null ? (
              !props.hideHireButton && (
                <Link to="/empresas" className="link-style">
                  Contratar con Laburo
                </Link>
              )
            ) : (
              <Link to="/dashboard" className="link-style">
                Panel De Control
              </Link>
            )}
          </div>
          <div>
            {user === null ? (
              <Link to="/ingresar" className="link-style laburo-green">
                Iniciar Sesión
              </Link>
            ) : (
              <button
                className="laburo-green"
                onClick={() => signOut(auth)}
                style={{
                  backgroundColor: "inherit",
                  fontSize: "22px",
                }}
              >
                Cerrar Sesión
              </button>
            )}
          </div>
          {/* cerrar sesion */}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
