import React, { useState } from "react";
import LoadingWidget from "../../widgets/LoadingWidget";

interface ConfirmPopUpProps {
  question: string;
  onConfirm: () => void;
  onCancel: () => void;
}

function ConfirmPopUp({ question, onConfirm, onCancel }: ConfirmPopUpProps) {
  const [loading, setLoading] = useState(false);
  return (
    <div className="confirm-pop-up-container">
      <div className="confirm-pop-up-box">
        <p className="mb-4 text-lg" style={{ textAlign: "center" }}>
          {question}
        </p>

        <p
          className="mb-4 txt-s2"
          style={{ textAlign: "center", color: "rgba(255, 0, 0, 0.7)" }}
        >
          Esta acción no se puede deshacer.
        </p>

        {loading ? (
          <LoadingWidget loading={loading} />
        ) : (
          <div className="buttons-container">
            <button className="cancel-button button-hover" onClick={onCancel}>
              Cancelar
            </button>
            <button
              className="confirm-button button-hover"
              onClick={() => {
                setLoading(true);
                onConfirm();
              }}
            >
              Confirmar
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ConfirmPopUp;
