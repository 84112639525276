import React, { useState, useEffect } from "react";

// Importing core components
import QuillEditor from "react-quill";

// Importing styles
import "../assets/styles/richtext.css";
import "react-quill/dist/quill.snow.css";

interface RichTextProps {
  editorName: string;
  htmlValue: string;
  setHTMLValue: React.Dispatch<React.SetStateAction<string>>;
}

function RichTextEditor({
  editorName,
  htmlValue,
  setHTMLValue,
}: RichTextProps) {
  const [value, setValue] = useState(htmlValue);

  useEffect(() => {
    setValue(htmlValue);
  }, [htmlValue]);

  const handleChange = (content: string) => {
    setValue(content);
    setHTMLValue(content);
  };

  return (
    <div className="wrapper">
      <label className="label" style={{ fontSize: "18px" }}>
        {editorName}
      </label>
      <QuillEditor
        className="editor"
        theme="snow"
        value={value}
        onChange={handleChange}
        modules={{
          toolbar: [
            [{ size: ["small", false, "large"] }],
            ["bold", "italic", "underline"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
          ],
        }}
        style={{ fontSize: "30px" }}
      />
    </div>
  );
}

export default RichTextEditor;
