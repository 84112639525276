import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deductPost } from "../../utils/businessUserFunctions";
import { getAuth } from "firebase/auth";
import LoadingWidget from "../widgets/LoadingWidget";

import { JobInt } from "../../typescript/interfaces/JobInterface";

interface PostJobSignedInProps {
  job: JobInt;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

function PostJobSignedIn(props: PostJobSignedInProps) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const user = auth.currentUser?.email;
      if (!user) {
        throw new Error("User not authenticated");
      }

      const result = await deductPost(user, props.job);
      console.log("deductPost result:", result); // Add logging here
      if (
        result ===
        "Post deducted, activated, and confirmation sent successfully"
      ) {
        // Redirect to success page
        console.log("Navigating to success page"); // Add logging here
        navigate("/post-job?success=true");
      } else {
        console.log("Navigating to failure page"); // Add logging here
        navigate("/post-job?success=false");
        throw new Error("Failed to deduct post");
      }
      props.setStep(3);
    } catch (error) {
      console.error("Error posting job:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingWidget loading={isLoading} />
      ) : (
        <form className="flx flx-center mt-25 " onSubmit={handleSubmit}>
          <button
            type="submit"
            className="postjob-createjob-button"
            style={{ color: "white" }}
          >
            Publicar
          </button>
        </form>
      )}
    </React.Fragment>
  );
}

export default PostJobSignedIn;
