import googleLogo from "../../assets/images/googleLogo.png";
import { signInWithPopup, GoogleAuthProvider, getAuth } from "firebase/auth";
import { useState } from "react";
import { createBusinessUser } from "../../utils/businessUserFunctions";

import "../../assets/styles/login.css";

interface GoogleSignUpProps {
  startingSignUp: () => void;
  signUpComplete: () => void;
}
const GoogleSignUp: React.FC<GoogleSignUpProps> = ({
  startingSignUp,
  signUpComplete,
}) => {
  const [error, setError] = useState(false);
  const [googleErrorMessage, setGoogleErrorMessage] = useState("");

  // Instantiate the auth service SDK
  const auth = getAuth();

  // Handle user sign up with Google
  const handleGoogleSignUp = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // Instantiate a GoogleAuthProvider object
    const provider = new GoogleAuthProvider();

    try {
      // Sign in with a pop-up window
      startingSignUp();
      const result = await signInWithPopup(auth, provider);

      // Pull signed-in user credential.
      const user = result.user;
      console.log("User signed in:", user);

      // Create business user in MongoDB
      if (user.email) {
        const created = await createBusinessUser(user.email);
        if (created) {
          console.log("Business user created successfully");
        } else {
          console.error("Failed to create business user");
        }
      }
      signUpComplete();
    } catch (err: any) {
      // Handle errors here.
      const errorMessage = err.message;
      const errorCode = err.code;

      setError(true);

      switch (errorCode) {
        case "auth/operation-not-allowed":
          setGoogleErrorMessage(
            "Las cuentas de correo electrónico/contraseña no están habilitadas."
          );
          break;
        case "auth/operation-not-supported-in-this-environment":
          setGoogleErrorMessage(
            "El protocolo HTTP no es compatible. Por favor, use HTTPS."
          );
          break;
        case "auth/popup-blocked":
          setGoogleErrorMessage(
            "El popup ha sido bloqueado por el navegador. Por favor, permita popups para este sitio web."
          );
          break;
        case "auth/popup-closed-by-user":
          setGoogleErrorMessage(
            "El popup ha sido cerrado por el usuario antes de finalizar la operación. Por favor, inténtelo de nuevo."
          );
          break;
        default:
          setGoogleErrorMessage(errorMessage);
          break;
      }
    }
  };

  return (
    <div className="signupContainer__box__google">
      <button
        onClick={handleGoogleSignUp}
        className="signupContainer_button_google"
      >
        <img
          src={googleLogo}
          style={{ height: "auto", width: "20px" }}
          alt="Google Logo"
        />
        <div className="google-txt">Registrarse con Google</div>
      </button>
      {error && <p>{googleErrorMessage}</p>}
    </div>
  );
};

export default GoogleSignUp;
