import axios from "axios";

const addNewsletterUser = async (email: string) => {
  try {
    const res = await axios.post(
      "https://addnewsletteruser-gi2cautoja-rj.a.run.app",
      { email: email }
    );
    return res;
  } catch (e) {
    return null;
  }
};

const removeNewsletterUser = async (email: string) => {
  try {
    const res = await axios.post(
      "https://removenewsletteruser-gi2cautoja-rj.a.run.app",
      { email: email }
    );
    return res;
  } catch (e) {
    return null;
  }
};

export { addNewsletterUser, removeNewsletterUser };
