import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useSearchParams } from "react-router-dom";
import {
  generateJobPostingStructuredData,
  getJobById,
} from "../utils/jobsUtils";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import axios from "axios";
import RichTextEditor from "../components/RichTextEditor";

import LoadingWidget from "../components/widgets/LoadingWidget";
import ResumeUploader from "../components/resume/ResumeUploader";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { JobInt } from "../typescript/interfaces/JobInterface";
import JobPost from "../components/JobPost";
import { checkApplicantExists } from "../utils/applicantFunctions";
import { Question } from "../typescript/interfaces/CustomQuestionInt";
import { AnswersInt } from "../typescript/interfaces/AppInterface";

function JobApply() {
  const storage = getStorage();
  const [jobId, setJobId] = useState("");
  const [jobName, setJobName] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [searchParams] = useSearchParams();
  const [formAlert, setformAlert] = useState(false);
  const [userAlreadyExistsAlert, setUserAlreadyExistsAlert] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currJob, setCurrJob] = useState<JobInt>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState<File>();
  const alertBox = useRef<HTMLDivElement>(null);
  const [answers, setAnswers] = useState<AnswersInt>({});
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);

  const handleAnswerChange = (question: string, answer: string) => {
    setAnswers((prev) => ({ ...prev, [question]: answer }));
  };

  const handleScroll = () => {
    if (alertBox.current) {
      // Get the element's current position relative to the top of the viewport
      const elementTop = alertBox.current.getBoundingClientRect().top;

      // Calculate the offset needed to place the element 70px from the top
      const offset = elementTop - 80;

      // Scroll the window by the calculated offset
      window.scrollBy({
        top: offset,
        behavior: "smooth",
      });
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Check if all required questions have answers
    const reqQuestions = currJob?.questions.every(
      (question) => answers[question.question]?.trim() !== ""
    );

    if (resume && name && number && email && reqQuestions) {
      setformAlert(false);
      setLoading(true);

      // check if email is already registered

      const userExists = await checkApplicantExists(jobId, email);
      if (!userExists) {
        let url = ""; // Default to empty string if no file is uploaded

        if (resume instanceof File) {
          // Proceed with the file upload
          const storageRef = ref(
            storage,
            `/files/${new Date().getTime()}__${jobId}_${name}`
          );
          const uploadTask = uploadBytesResumable(storageRef, resume);

          try {
            await new Promise((resolve, reject) => {
              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  const percent =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  setUploadStatus(percent);
                },
                (err) => reject(err),
                () => resolve(uploadTask.snapshot.ref)
              );
            });

            url = await getDownloadURL(uploadTask.snapshot.ref);
          } catch (err) {
            console.error("Upload or URL retrieval failed:", err);
            alert("Upload failed: " + err);
            setLoading(false);
            return; // Stop further execution on error
          }
        }

        // Prepare data to send, regardless of whether a file was uploaded
        const apiData = {
          to: currJob?.recieveEmail,
          subject: "Aplicación para la posición de " + jobName,
          html: description,
          company: currJob?.company,
          name,
          number,
          email,
          date: new Date(),
          description,
          jobName: jobName,
          resume,
          resumeUrl: url,
          jobId: jobId,
          answers: answers,
        };

        const apiUrl = "https://sendmessage-gi2cautoja-rj.a.run.app";

        try {
          await axios.post(apiUrl, apiData);
          navigate("/thank-you");
        } catch (error) {
          console.error("Failed to send application: ", error);
          alert("Error: No se pudo enviar la aplicación: " + error);
        } finally {
          setLoading(false);
        }
      } else {
        setUserAlreadyExistsAlert(true);
        setLoading(false);
      }
    } else {
      setformAlert(true); // Show alert if not all fields are filled
      setLoading(false);
    }
  };
  const showEmptyFields = () => {
    let questionsReq =
      Object.keys(answers).length === currJob?.questions.length;
    let names = ["CV", "Nombre", "Email", "Teléfono", "Preguntas Adicionales"];
    let form = [resume, name, email, number, questionsReq];
    return form.map((val, index) => {
      if (!val) {
        return <div key={index}>{names[index]}</div>;
      }
      return null;
    });
  };

  const handleInvalidEmail = (e: React.FormEvent<HTMLInputElement>) => {
    // Set a custom message in Spanish
    // e.currentTarget.setCustomValidity(
    //   "Por favor introduce una dirección de correo válida."
    // );
  };
  // disable button until the data is populated
  useEffect(() => {
    // const name = searchParams.get("name");
    const fetchJobData = async () => {
      // get the job data from aprams
      const id = searchParams.get("id");
      let ID = searchParams.get("ID");
      if (id) {
        const jobData = await getJobById(id);
        setJobId(id);
        setCurrJob(jobData);
        setJobName(jobData.title);
        document.title = `${jobData.title} - ${jobData.company}`;
        updateMetaTags(
          jobData.title,
          jobData.description,
          "URL to your image",
          window.location.href
        );
        // Inject structured data
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.innerHTML = JSON.stringify(
          generateJobPostingStructuredData(jobData)
        );
        document.head.appendChild(script);
      } else if (ID) {
        ID = ID.toLowerCase();
        const jobData = await getJobById(ID);
        setJobId(ID);
        setCurrJob(jobData);
        setJobName(jobData.title);
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.innerHTML = JSON.stringify(
          generateJobPostingStructuredData(jobData)
        );
        document.head.appendChild(script);
      }
    };

    fetchJobData();
  }, [searchParams]);

  useEffect(() => {
    if (formAlert || userAlreadyExistsAlert) {
      handleScroll();
      setTimeout(() => {
        if (formAlert) {
          setformAlert(false);
        } else {
          setUserAlreadyExistsAlert(false);
        }
      }, 10000);
    }
  }, [formAlert, userAlreadyExistsAlert]);
  const updateMetaTags = (
    title: string,
    description: string,
    imageUrl: string,
    url: string
  ) => {
    const metaTitle = document.getElementById("meta-og-title");
    const metaDescription = document.getElementById("meta-og-description");
    // const metaImage = document.getElementById("meta-og-image");
    const metaUrl = document.getElementById("meta-og-url");

    if (metaTitle) metaTitle.setAttribute("content", title);
    if (metaDescription) metaDescription.setAttribute("content", description);
    // if (metaImage) metaImage.setAttribute("content", imageUrl);
    if (metaUrl) metaUrl.setAttribute("content", url);
  };
  return (
    <div className=" flx-col skip-navbar-margin ">
      <Navbar scrollPast={true} />

      <div
        className="flx-col  w100"
        style={{ minHeight: "100vh", alignItems: "center" }}
      >
        <form onSubmit={handleSubmit} className="flx flx-col flx-center">
          <div className=" job-des-content-apply  ">
            <div className="mt-25 w100 mb-25">
              {/* link back to job des prior */}

              <Link
                to={`/jobs`}
                className="link-style "
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  fontSize: "18px",
                }}
              >
                <ArrowBackIcon /> Regresar
              </Link>
            </div>
            <div className="w100 mb-25">
              {" "}
              {currJob ? (
                <JobPost currJob={currJob} />
              ) : (
                <div className="w100 flx flx-center">
                  <LoadingWidget loading={true} />
                </div>
              )}
            </div>

            <div className="mb-50 w100 " style={{ padding: "0px 20px" }}>
              <div className="mb-25 txt-s4"> Descripción: </div>
              {currJob && (
                <div
                  dangerouslySetInnerHTML={{ __html: currJob?.description }}
                  className="sidebar-content-description "
                  style={{ maxHeight: "none" }}
                ></div>
              )}

              <div
                className="w100"
                style={{
                  border: "1px solid rgba(0,0,0,0.2)",
                  marginTop: "25px",
                }}
              ></div>
            </div>

            {formAlert && (
              <div className="w100 mb-25" ref={alertBox}>
                <Alert severity="error" style={{ borderRadius: "10px" }}>
                  <div className="">
                    Por favor completa estos campos: {showEmptyFields()}
                  </div>
                </Alert>
              </div>
            )}
            {userAlreadyExistsAlert && (
              <div className="w100 mb-25" ref={alertBox}>
                <Alert severity="error" style={{ borderRadius: "10px" }}>
                  <div className="">
                    ¡Ya te has postulado a esta posición! No puedes volver a
                    aplicar 😔
                  </div>
                </Alert>
              </div>
            )}
            <div
              className="w100 flx flx-col"
              style={{ gap: "30px", height: "100%" }}
            >
              <div
                className="w100 postjob-gray-container"
                style={{ paddingTop: "40px", paddingBottom: "40px" }}
              >
                <ResumeUploader setResume={setResume} status={uploadStatus} />

                <div className="w100 mt-25">
                  <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                    {" "}
                    Nombre
                  </div>

                  <div className="search-pill">
                    <input
                      type="text"
                      className="search-pill-input "
                      value={name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="w100">
                  <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                    {" "}
                    Email
                  </div>

                  <div className="search-pill">
                    <input
                      type="email"
                      className="search-pill-input "
                      onInvalid={handleInvalidEmail}
                      value={email}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setEmail(e.target.value);
                        e.target.setCustomValidity("");
                      }}
                    />
                  </div>
                </div>
                <div className="w100">
                  <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                    {" "}
                    Telefono
                  </div>

                  <div className="search-pill">
                    <input
                      type="text"
                      className="search-pill-input "
                      value={number}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="w100">
                  {/* <div style={{ marginBottom: "10px" }}>Descripción</div> */}
                  {/* <div className=" job-des-input"> */}
                  <RichTextEditor
                    editorName="Información Adicional (Opcional)"
                    htmlValue={description}
                    setHTMLValue={setDescription}
                  />
                  {/* <textarea
                className="job-des-input-pill"
                value={description}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setDescription(e.target.value);
                }}
              /> */}
                  {/* </div> */}
                </div>
              </div>
              {currJob?.questions && currJob.questions.length > 0 && (
                <div
                  className="w100 postjob-gray-container "
                  style={{
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    height: "100%",
                  }}
                >
                  <div
                    className="w100"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="mb-25" style={{ fontSize: "28px" }}>
                      Preguntas Adicionales
                    </div>
                    {currJob?.questions &&
                      currJob.questions.map(
                        (question: Question, index: number) => (
                          <div
                            key={index}
                            className="mb-25 "
                            style={{ height: "100%" }}
                          >
                            <div
                              style={{ marginBottom: "10px", fontSize: "18px" }}
                            >
                              {question.question}
                            </div>
                            {question.type === "text" ? (
                              <div
                                className="search-pill"
                                style={{ height: "100%" }}
                              >
                                <textarea
                                  className="search-pill-input text-input-post-jobs"
                                  value={answers[question.question] || ""}
                                  onChange={(e) =>
                                    handleAnswerChange(
                                      question.question,
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    height: "100%",
                                    minHeight: "150px",
                                    resize: "vertical",
                                    padding: "10px",
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="postjob-custom-questions-container-row-select-wrapper ">
                                <div
                                  className="postjob-custom-questions-container-row-select "
                                  onClick={() =>
                                    setOpenDropdown(
                                      openDropdown === index ? null : index
                                    )
                                  }
                                >
                                  {answers[question.question] ||
                                    "Selecciona una opción"}
                                </div>
                                {openDropdown === index && (
                                  <div className="postjob-custom-questions-container-row-select-options ">
                                    {question.options?.map(
                                      (option, optionIndex) => (
                                        <div
                                          key={optionIndex}
                                          className="postjob-custom-questions-container-row-select-option"
                                          onClick={() => {
                                            handleAnswerChange(
                                              question.question,
                                              option
                                            );
                                            setOpenDropdown(null);
                                          }}
                                        >
                                          {option}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="w100 flx-center flx-col mb-50 mt-25 ">
            {loading ? (
              <LoadingWidget loading={loading} />
            ) : (
              <button
                type="submit"
                className="aplicar-btn button-hover"
                style={{ color: "white" }}
                disabled={loading}
              >
                Enviar
              </button>
            )}
          </div>
        </form>
        <Footer type={2} />
      </div>
    </div>
  );
}

export default JobApply;
