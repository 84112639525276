import React, { useEffect, useState } from "react";

import "../../assets/styles/job-sidebar.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import PDFViewer from "../PDFViewer";
import LoadingWidget from "../widgets/LoadingWidget";
import { updateApplicantById } from "../../utils/applicantFunctions";
import AIScore from "../dashboard/tools/AIScore";

interface JobDesdPopupProps {
  selectedJob: ApplicantInt;
  setPopOpen: React.Dispatch<React.SetStateAction<boolean>>;
  likedApp: boolean;
  setlikedApp: React.Dispatch<React.SetStateAction<boolean>>;
}
function ApplicantSideBarPopUp({
  selectedJob,
  setPopOpen,
  likedApp,
  setlikedApp,
}: JobDesdPopupProps) {
  const [loadingHeart, setLoadingHeart] = useState(false);

  useEffect(() => {
    // Add the class to disable scroll when the component mounts

    if (window.innerWidth < 750) {
      document.body.classList.add("body-no-scroll");

      // Cleanup function to remove the class when the component unmounts
    } else {
      setPopOpen(false);
    }
    return () => {
      document.body.classList.remove("body-no-scroll");
    };
  }, [setPopOpen]);

  const hadleApplicantLike = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setLoadingHeart(true);
    setlikedApp(!likedApp);

    const result = await updateApplicantById(selectedJob);
    if (!result) {
      setlikedApp(!likedApp);
    }

    setLoadingHeart(false);
  };

  const handleOpenWhatsApp = (e: React.MouseEvent) => {
    e.preventDefault();
    let phoneNumber = selectedJob?.number?.replace(/\D/g, "") || "";

    if (phoneNumber.startsWith("0")) {
      phoneNumber = phoneNumber.substring(1);
    }

    phoneNumber = `593${phoneNumber}`;

    const whatsappUrl = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      ? `whatsapp://send?phone=${phoneNumber}`
      : `https://wa.me/${phoneNumber}`;

    window.location.href = whatsappUrl;
  };

  const handleCall = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.href = `tel:${selectedJob?.number}`;
  };

  const handleEmail = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.href = `mailto:${selectedJob?.email}`;
  };

  return (
    <div className="jsb-popup-container">
      <div className="jsb-popup-window">
        <div className={`w100 job-sidebar-content `} style={{ height: "100%" }}>
          <div className="js-content-top" style={{ gap: "10px" }}>
            <div className="flx">
              <div
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  setPopOpen(false);
                  console.log("clicked");
                }}
                className="flx jsb-back-button"
                style={{ alignItems: "center", fontSize: "18px" }}
              >
                <ArrowBackIcon /> Regresar
              </div>
            </div>

            <div
              className="flx w100"
              style={{ justifyContent: "space-between" }}
            >
              <div className="txt-s4 job-post-position">
                {selectedJob.name ? selectedJob.name : "name"}
              </div>
              <div onClick={hadleApplicantLike}>
                {loadingHeart ? (
                  <div
                    className="flx flx-center"
                    style={{ width: "24px", height: "24px" }}
                  >
                    <LoadingWidget loading={true} small={true} />
                  </div>
                ) : likedApp ? (
                  <FavoriteIcon style={{ color: "red" }} />
                ) : (
                  <FavoriteBorderIcon />
                )}
              </div>
            </div>
            <div
              style={{
                fontSize: "19px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {selectedJob?.email}
              <MailOutlineOutlinedIcon
                onClick={handleEmail}
                style={{
                  cursor: "pointer",
                  verticalAlign: "middle",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "18px",
                color: "rgba(0,0,0,0.5)",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {selectedJob?.number}
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <LocalPhoneRoundedIcon
                  onClick={handleCall}
                  style={{
                    cursor: "pointer",
                    verticalAlign: "middle",
                    color: "black",
                    fontSize: "24px",
                  }}
                />
                <WhatsAppIcon
                  onClick={handleOpenWhatsApp}
                  style={{
                    cursor: "pointer",
                    color: "#25D366",
                    verticalAlign: "middle",
                    fontSize: "28px",
                  }}
                />
              </div>
            </div>
            {/* logic to take user to apply or  */}
          </div>
          {/* ----------- */}
          <div className="js-content-btm">
            <div className="js-content-btm-desc">
              {selectedJob?.description && (
                <div style={{ fontSize: "20px", color: "rgba(0,0,0,0.6)" }}>
                  Información
                </div>
              )}

              {selectedJob?.description && (
                <div
                  dangerouslySetInnerHTML={{ __html: selectedJob?.description }}
                  className="sidebar-content-description "
                  style={{ maxHeight: "none" }}
                ></div>
              )}
              {selectedJob.answers && (
                <div className="flx flx-col" style={{}}>
                  <div
                    style={{
                      fontSize: "20px",
                      color: "rgba(0,0,0,0.6)",
                      marginBottom: "10px",
                    }}
                  >
                    Respuestas del Candidato
                  </div>
                  {Object.entries(selectedJob.answers).map(
                    ([question, answer], index) => (
                      <div key={index} className="flx flx-col">
                        <div
                          style={{
                            fontWeight: "bold",
                            color: "rgba(0,0,0,0.8)",
                            marginBottom: "5px",
                          }}
                        >
                          {question}:
                        </div>
                        <div
                          style={{
                            paddingLeft: "10px",
                            color: "rgba(0,0,0,0.7)",
                            fontSize: "16px",
                          }}
                        >
                          {answer}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
              {selectedJob?.score && (
                <div className="flx flx-col">
                  <div
                    className="txt-s4"
                    style={{ color: "rgba(0,0,0,0.6", marginBottom: "10px" }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      Evaluación Laburo
                    </div>
                    {selectedJob?.calculatedScore && (
                      <AIScore score={selectedJob.calculatedScore} />
                    )}
                  </div>
                  <div style={{ marginBottom: "5px", fontWeight: "bold" }}>
                    Puntos a favor
                  </div>
                  <ul style={{ listStyleType: "disc", paddingLeft: "15px" }}>
                    {selectedJob.score?.["Strong Points"].map(
                      (point: string, index) => {
                        return <li key={index}>{point}</li>;
                      }
                    )}
                  </ul>
                  <div
                    style={{
                      marginBottom: "5px",
                      marginTop: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Puntos en Contra
                  </div>
                  <ul style={{ listStyleType: "disc", paddingLeft: "15px" }}>
                    {selectedJob.score?.["Weak Points"].map(
                      (point: string, index) => {
                        return <li key={index}>{point}</li>;
                      }
                    )}
                  </ul>
                </div>
              )}
            </div>

            <div
              style={{
                padding: "10px 10px",
              }}
            >
              <div
                className="txt-s4"
                style={{
                  color: "rgba(0,0,0,0.6)",
                  marginBottom: "5px",
                  paddingLeft: "10px",
                }}
              >
                <div>Hoja de Vida</div>
              </div>
              <PDFViewer path={selectedJob.resumeUrl} name={selectedJob.name} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicantSideBarPopUp;
