import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import businessLanding from "../../assets/images/landing/businesslanding.png";
import "../../assets/styles/landing/businessLanding.css";
import StepsCards from "../../components/landing/StepsCards";
import ScreenCarousel from "../../components/landing/ScreenCarousel";
import PriceCards from "../../components/landing/PriceCards";
import Footer from "../../components/Footer";
import videoPlaceholder from "../../assets/images/landing/videoPlaceholder.png";
function BusinessLanding() {
  const [currScreen, setCurrScreen] = useState(1);
  const navigate = useNavigate();
  const demoVideo =
    "https://firebasestorage.googleapis.com/v0/b/hrbot-e8686.appspot.com/o/assets%2Fdemo.mp4?alt=media&token=83a78737-0c30-4d76-b7c3-6390681ea32c";

  return (
    <div className="bl-container">
      <Navbar scrollPast={true} lockNavbar={true} hideHireButton={true} />
      <div className="bl-content">
        <div className="bl-box">
          <div className="bl-hero">
            <div className="bl-hero-text">
              <div className="bl-hero-text-title">
                La solución para tu equipo, en minutos
              </div>
              <div className="bl-hero-text-subtitle">
                <div style={{ maxWidth: "400px" }}>
                  Laburo es la plataforma de empleo que centraliza y simplifica
                  la gestión de tus vacantes.
                </div>
              </div>

              <div
                className="bl-hero-button button-hover bl-screen-big"
                onClick={() => navigate("/ingresar")}
              >
                Publicar Vacante
              </div>

              <div className="flx flx-center bl-screen-small">
                <div
                  className="bl-hero-button button-hover "
                  onClick={() => navigate("/ingresar")}
                >
                  Publicar Vacante
                </div>
              </div>
            </div>

            <img
              className="bl-hero-img"
              src={businessLanding}
              alt="laburo empresa"
            />
          </div>

          <div className="bl-steps">
            <div className="bl-steps-container">
              <div className="bl-steps-screen">
                {" "}
                <ScreenCarousel screen={currScreen} />
              </div>

              <StepsCards setScreenNumber={setCurrScreen} />
            </div>
          </div>

          <div className="bl-demo">
            <div className="bl-demo-bg">
              <div>
                <div className="bl-demo-title ">Miralo en acción</div>
                <div className="bl-demo-subtitle">
                  Se acabaron los días de manejar vacantes por correo o whatsapp{" "}
                </div>
              </div>
              <div className="bl-demo-video">
                <video
                  controls
                  preload="metadata"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  poster={videoPlaceholder}
                >
                  <source src={demoVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div className="bl-pricing">
            <div
              className="flx flx-col"
              style={{
                gap: "10px",
              }}
            >
              <div className="bl-pricing-title">
                Escoge el plan que mejor se adapte a ti{" "}
              </div>
              <div className="bl-pricing-subtitle">
                Opciones para todos los tamaños de empresa
              </div>
            </div>

            <PriceCards />

            <div className="bl-free-post">
              <div className="bl-free-post-title ">Primera vacante gratis</div>
              <div className="bl-free-post-subtitle mb-25">
                regístrate y accede a una publicación gratuita
              </div>
              <div
                className="bl-free-post-button button-hover"
                onClick={() => navigate("/registrar")}
              >
                Regístrate
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer type={1} />
    </div>
  );
}

export default BusinessLanding;
