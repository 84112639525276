import React, { useState } from "react";
import "../../assets/styles/newsletter/newsletterSignUp.css";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { addNewsletterUser } from "../../utils/Newsletter/newsletterFunctions";
import { Alert } from "@mui/material";
import LoadingWidget from "../widgets/LoadingWidget";

interface NWSProps {
  setNWSOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function NewsLetterSignUp(props: NWSProps) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      const res = await addNewsletterUser(email);

      if (res !== null && res.status === 201) {
        setSuccessAlert(true);
        setTimeout(() => {
          setSuccessAlert(false);
        }, 3000); // 3 seconds
      } else if (res !== null && res.status === 200) {
        setErrorAlert(true);
        setErrorMessage("El usuario ya está registrado");
        setTimeout(() => {
          setErrorAlert(false);
        }, 3000); // 3 seconds
      } else {
        setErrorAlert(true);
        setErrorMessage("Ha ocurrido un error, por favor intente nuevamente");
        setTimeout(() => {
          setErrorAlert(false);
        }, 3000); // 3 seconds
      }
    } catch (error) {
      setErrorAlert(true);
      setErrorMessage("Ha ocurrido un error, por favor intente nuevamente");
      setTimeout(() => {
        setErrorAlert(false);
      }, 3000); // 3 seconds
    }

    setLoading(false);
  };

  return (
    <div className="nws-container ">
      <div className="nws-box">
        <div
          onClick={() => {
            props.setNWSOpen(false);
            localStorage.setItem("isPopupDismissed", "true");
          }}
          className="button-hover"
        >
          <CloseIcon className="nws-close" style={{ fontSize: "30px" }} />
        </div>
        <div className="nws-title">¡No te pierdas las mejores vacantes!</div>
        <div className="nws-content mt-25">
          Ingresa tu correo y te mantendremos al día con ofertas de empleo,
          consejos para conseguir trabajo y mucho más.
        </div>
        <form onSubmit={handleSubmit}>
          <div className="nws-input-box-container">
            <input
              type="email"
              onChange={handleChange}
              value={email}
              required
            />
            {loading ? (
              <LoadingWidget loading={loading} />
            ) : (
              <button
                type="submit"
                className="nws-submit-btn button-hover bg-laburo-green"
              >
                <div className="desktop-only">Registrarse</div>
                <div className="mobile-only">Enviar</div>
              </button>
            )}
          </div>
        </form>
        <div style={{ margin: "10px 0px " }}>
          {errorAlert && (
            <Alert severity="error" style={{ borderRadius: "10px" }}>
              <div>{errorMessage}</div>
            </Alert>
          )}
          {successAlert && (
            <Alert severity="success" style={{ borderRadius: "10px" }}>
              <div>El usuario ha sido registrado con éxito</div>
            </Alert>
          )}
        </div>
        <div className="nws-disclaimer">
          *Al ingresar y enviar tu correo, aceptas nuestra{" "}
          <Link to="/privacy" style={{ textDecoration: "underline" }}>
            Política de Privacidad
          </Link>
          . Tu correo será utilizado exclusivamente para recibir nuestro
          contenido y no será compartido con terceros.
        </div>
      </div>
    </div>
  );
}

export default NewsLetterSignUp;
