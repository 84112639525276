import React from "react";
import "../assets/styles/footer.css";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
interface FooterProps {
  type: number;
}

function Footer({ type }: FooterProps) {
  return (
    <div
      className="footer-container "
      style={{
        color: `${type === 1 ? "white" : "black"}`,
        backgroundColor: `${type === 1 ? "#000000cc" : "white"}`,
      }}
    >
      {type === 1 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="flx flx-col w100 flx-center"
            style={{ padding: "0px 15px" }}
          >
            <div className="footer-sub-container">
              <div className="hire-text">
                <div
                  className="flx flx-col"
                  style={{
                    alignItems: "baseline",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div className="txt-s5 mb-25 txt-pos w100">
                    Listo Para Contratar ?
                  </div>
                  <div className="txt-s4 txt-pos" style={{ width: "100%" }}>
                    Usa nuestra plataforma para llegar a miles de aplicantes y
                    asegurarte de conseguir el mejor talento.
                  </div>
                </div>

                <div className="footer-button">
                  <Link
                    to="/ingresar"
                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                    className="button-hover"
                  >
                    <div className="post-job-btn2 ">Publica un trabajo</div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="footer-line-break"> </div>

            <div
              className="flx w100 space-even mb-50"
              style={{ maxWidth: "1000px" }}
            >
              <Link to={"/about-us"}>nosotros </Link>
              <Link to={"/contact-us"}>contáctanos </Link>
              <Link to={"/privacy"}>privacidad </Link>
            </div>
            <div className="flx" style={{ gap: "10px" }}>
              <Link
                to="https://www.facebook.com/profile.php?id=61560401591324"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </Link>
              <Link
                to="https://www.instagram.com/laburoecuador/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </Link>
              <Link
                to="https://www.tiktok.com/@laburoecuador"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TikTokIcon />
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5px",
          }}
        >
          <div className="flx" style={{ gap: "10px" }}>
            <Link
              to="https://www.facebook.com/profile.php?id=61560401591324"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </Link>
            <Link
              to="https://www.instagram.com/laburoecuador/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </Link>
            <Link
              to="https://www.tiktok.com/@laburoecuador"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TikTokIcon color="black" />
            </Link>
          </div>
        </div>
      )}

      <div style={{ paddingTop: "10px" }}>
        <div style={{ fontSize: "12px", marginBottom: "15px" }}>
          <div
            className="flx flx-center"
            style={{ fontSize: "14px", alignItems: "baseline" }}
          >
            <div> Hecho con </div>{" "}
            <div style={{ fontSize: "12px", margin: "0px 3px" }}> ❤️ </div>
            <div>en </div>
            <div
              className="flx "
              style={{
                fontSize: "14px",
                margin: "0px 5px",
              }}
            >
              Ecuador
            </div>
          </div>
          Humm Labs. Reservados todos los Derechos 2024
        </div>
      </div>
    </div>
  );
}

const TikTokIcon = ({ color = "#FFFFFF" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="23px"
      height="23px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};
export default Footer;
