import React from "react";
import "../../assets/styles/dashboard/positions.css";

import { JobInt } from "../../typescript/interfaces/JobInterface";
import JobPostingRow from "./JobPostingRow";
import { Link } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";

interface PositionsProps {
  positions: JobInt[];
  getPositions: () => Promise<void>;
  postsAvailable: number;
}

function Positions(props: PositionsProps) {
  return (
    <React.Fragment>
      <div className="posts-available-container"></div>
      <div className="positions-container ">
        <div className="positions-box">
          <div
            className="txt-s3"
            style={{ textAlign: "right", paddingRight: "10px" }}
          >
            Publicaciones Disponibles:{" "}
            <span className="laburo-green">{props.postsAvailable}</span>{" "}
          </div>
          <div className="positions-box-filters"></div>

          <div className="positions-box-table" style={{ position: "relative" }}>
            {props.positions.length !== 0 && (
              <div
                className="positions-box-table-row "
                style={{
                  border: "none",
                  alignItems: "flex-end",
                  height: "100%",
                  fontSize: "18px",
                }}
              >
                <div className="positions-box-table-cell ">
                  <div className="btc-title">Vacante</div>
                </div>
                <div className="positions-box-table-cell  desktop-only">
                  <div className="btc-title">Lugar</div>
                </div>
                <div className="positions-box-table-cell  ">
                  <div className=" flx " style={{ marginLeft: "10px" }}>
                    <div className="btc-title">
                      <PeopleIcon />
                    </div>
                  </div>
                </div>
                <div className="positions-box-table-cell  desktop-only">
                  <div className="btc-title">Publicado</div>
                </div>
                <div className="positions-box-table-cell ">
                  {" "}
                  <div className="btc-title">Activo</div>
                </div>
                <div
                  className="positions-box-table-cell "
                  style={{ height: "100%" }}
                >
                  <Link to="/post-job" className="button-hover">
                    <div
                      className="add-position-btn-small flx flx-center  btc-title"
                      style={{ whiteSpace: "nowrap", borderRadius: "50px" }}
                    >
                      <div
                        className="mobile-only flx flx-center w100"
                        style={{ gap: "5px" }}
                      >
                        Nueva
                      </div>
                      <div className="desktop-only">Nueva Vacante</div>
                    </div>
                  </Link>
                </div>
              </div>
            )}

            {props.positions && props.positions.length > 0 ? (
              props.positions?.map((pos, index) => {
                return (
                  <JobPostingRow
                    job={pos}
                    key={index}
                    getPositions={props.getPositions}
                  />
                );
              })
            ) : props.positions.length === 0 ? (
              <div className="flx-col flx-center txt-s4 ">
                <div className="mb-50" style={{ textAlign: "center" }}>
                  ¡Añade una vacante para conseguir el mejor talento!
                </div>
                <div>
                  {" "}
                  <Link to="/post-job" className="button-hover">
                    <div
                      className="add-position-btn flx flx-center"
                      style={{ whiteSpace: "nowrap", borderRadius: "50px" }}
                    >
                      <div className="">Crear Vacante</div>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              "cargando..."
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Positions;
