import React from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
// import AutocompleteLocation from "../components/AutocompleteLocation";
function ComingSoon() {
  return (
    <div>
      <Navbar scrollPast={true} />
      <div className="in-dev skip-navbar-margin">
        <div className="flx-col flx-center " style={{ maxWidth: "500px" }}>
          <div
            className="mb-25 thank-you-txt-title"
            style={{ fontSize: "30px" }}
          >
            ¡Gracias por confiar en nosotros!
          </div>

          <div
            className="in-dev-txt"
            style={{ textAlign: "center", color: "rgba(0,0,0,0.7)" }}
          >
            Tu applicación ha sido enviada exitosamente. La empresa se pondra en
            contacto contigo directamente. Que tengas un excelente dia y mucha
            suerte en tu busqueda.
          </div>

          <div className=" thank-you-txt-more-jobs  ">
            <div>Sigue explorando más oportunidades haciendo click aquí:</div>

            <div className="w100 flx flx-center mt-25">
              <Link
                to="/jobs"
                className="aplicar-btn button-hover"
                style={{ color: "white" }}
              >
                Más Trabajos
              </Link>
            </div>
          </div>

          {/* test autocomplete */}
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
