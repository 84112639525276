import React from "react";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import "../../assets/styles/dashboard/applicants.css";
import EmailIcon from "@mui/icons-material/Email";

import PDFViewer from "../PDFViewer";
import { Phone } from "@mui/icons-material";

import AIScore from "../dashboard/tools/AIScore";
interface ApplicantSideInfoProps {
  selected: ApplicantInt;
}

function ApplicantSideInfo(props: ApplicantSideInfoProps) {
  return (
    <div className="applicant-info-container">
      <div className="applicant-info-box">
        <div className="txt-s6">{props.selected.name}</div>
        <div className="flx flx-col" style={{ gap: "5px" }}>
          <div
            className="txt-s4"
            style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
          >
            Contacto
          </div>
          <div
            className="txt-s4 flx "
            style={{ alignItems: "center", gap: "10px" }}
          >
            <EmailIcon style={{ fontSize: "18px" }} /> {props.selected.email}
          </div>
          <div
            className="txt-s4 flx "
            style={{ alignItems: "center", gap: "10px" }}
          >
            <Phone style={{ fontSize: "18px" }} /> {props.selected.number}
          </div>
        </div>
        {props.selected.answers && (
          <div className="flx flx-col" style={{ gap: "10px" }}>
            <div
              className="txt-s4"
              style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
            >
              Respuestas del Candidato
            </div>
            {Object.entries(props.selected.answers).map(
              ([question, answer], index) => (
                <div
                  key={index}
                  className=" flx flx-col"
                  style={{ gap: "5px", fontSize: "20px" }}
                >
                  <div style={{ fontWeight: "bold", color: "rgba(0,0,0,0.8)" }}>
                    {question}:
                  </div>
                  <div
                    style={{
                      paddingLeft: "10px",
                      color: "rgba(0,0,0,0.7)",
                      fontSize: "18px",
                    }}
                  >
                    {answer}
                  </div>
                </div>
              )
            )}
          </div>
        )}

        {props.selected.description && (
          <div className="flx flx-col" style={{ gap: "5px" }}>
            <div
              className="txt-s4"
              style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
            >
              Información Adicional
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: props.selected.description }}
              className="validate-job-text ql-editor txt-s4"
              style={{
                height: "100%",
                minHeight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
              }}
            ></div>
          </div>
        )}
        {props.selected.score && (
          <div className="flx flx-col">
            <div
              className="txt-s4"
              style={{
                color: "rgba(0,0,0,0.6",
                marginBottom: "5px",
                fontSize: "23px",
              }}
            >
              <div style={{ marginBottom: "10px" }}>Evaluación Laburo</div>
              {props.selected.calculatedScore && (
                <AIScore score={props.selected.calculatedScore} />
              )}
            </div>
            <div style={{ marginBottom: "5px", fontSize: "20px" }}>
              Puntos a Favor
            </div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "15px",
                fontSize: "18px",
              }}
            >
              {props.selected.score?.["Strong Points"].map(
                (point: string, index) => {
                  return <li key={index}>{point}</li>;
                }
              )}
            </ul>
            <div
              style={{
                marginTop: "15px",
                marginBottom: "5px",
                fontSize: "20px",
              }}
            >
              Puntos en Contra
            </div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "15px",
                fontSize: "18px",
              }}
            >
              {props.selected.score?.["Weak Points"].map(
                (point: string, index) => {
                  return <li key={index}>{point}</li>;
                }
              )}
            </ul>
          </div>
        )}

        <div
          className="txt-s4"
          style={{ color: "rgba(0,0,0,0.6", fontSize: "23px" }}
        >
          Hoja de Vida / CV
        </div>
        <PDFViewer path={props.selected.resumeUrl} name={props.selected.name} />
      </div>
    </div>
  );
}

export default ApplicantSideInfo;
