import React from "react";
import "../../assets/styles/widgets.css";
interface LoadingWidgetProps {
  loading: boolean;
  small?: boolean;
}
function LoadingWidget(props: LoadingWidgetProps) {
  return (
    <React.Fragment>
      <div className="loading-container">
        {props.loading &&
          (props.small ? (
            <div
              className="loader"
              style={{ height: "12px", width: "12px" }}
            ></div>
          ) : (
            <div className="loader"></div>
          ))}
      </div>
    </React.Fragment>
  );
}

export default LoadingWidget;
