import React from "react";
import SignInForm from "../../components/authentication/SignInForm";
import Navbar from "../../components/Navbar";
import { Navigate } from "react-router-dom";
import useAuth from "../../utils/useAuth";
function SignIn() {
  const { user } = useAuth();
  // if user != null then return signed in else return dashboard

  return (
    <React.Fragment>
      {user === null ? (
        <React.Fragment>
          <Navbar scrollPast={true} highlightLogin={true} hideSignIn={true} />

          <SignInForm />
        </React.Fragment>
      ) : (
        <Navigate to="/dashboard" />
      )}
    </React.Fragment>
  );
}

export default SignIn;
