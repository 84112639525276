import React, { useState } from "react";
import SignUpForm from "../../components/authentication/SignUpForm";
import Navbar from "../../components/Navbar";
import { Navigate } from "react-router-dom";
import useAuth from "../../utils/useAuth";

function SignUp() {
  const { user } = useAuth();
  const [isSignUpComplete, setIsSignUpComplete] = useState(false);

  const startingSignUp = () => {
    setIsSignUpComplete(true);
  };
  const signUpComplete = () => {
    setIsSignUpComplete(false);
  };

  return (
    <React.Fragment>
      <Navbar scrollPast={true} hideSignIn={true} />
      {user && !isSignUpComplete ? (
        <Navigate to="/dashboard" />
      ) : (
        <SignUpForm
          signUpComplete={signUpComplete}
          startingSignUp={startingSignUp}
        />
      )}
    </React.Fragment>
  );
}

export default SignUp;
